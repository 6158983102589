
import { computed, defineComponent } from 'vue'
import DestinationCard from '@/components/DestinationCard.vue'
import NewsCard from '@/components/NewsCard.vue'
import Announcement from '@/components/Announcement.vue'
import CardScroll from '@/components/CardScroll.vue'
import GenericError from '@/components/GenericError.vue'
import { getDashboard } from '@/kentico-api/dashboard.api'
import { getLatestNews } from '@/kentico-api/news.api'
import useSwrv from 'swrv'
import FeaturedLinks from '@/components/FeaturedLinks.vue'
import { Dashboard } from '@/models/Dashboard'
import { useHead } from '@vueuse/head'
import imageUrlBuilder from '@/kentico-api/imageUrlBuilder'

export default defineComponent({
  components: {
    DestinationCard,
    NewsCard,
    CardScroll,
    Announcement,
    GenericError,
    FeaturedLinks
  },
  // eslint-disable-next-line complexity
  setup () {
    const { data: dashboardResponse, error } = useSwrv(
      '/dashboard',
      getDashboard
    )
    const { data: newsResponse, error: newsError } = useSwrv(
      '/latest-news',
      getLatestNews
    )

    const dashboard = computed(():Dashboard => dashboardResponse.value?.data as Dashboard)
    const latestNews = computed(() => newsResponse.value?.data)

    useHead({
      meta: [
        {
          name: 'og:description',
          content: computed(() => dashboard.value?.openGraphOgDescription || ''),
          key: 'ogDescription'
        },
        {
          name: 'og:title',
          content: computed(() => dashboard.value?.openGraphOgTitle || ''),
          key: 'ogTitle'
        },
        {
          name: 'og:url',
          content: process.env.VUE_APP_SITE_URL,
          key: 'ogUrl'
        },
        {
          name: 'og:image',
          itemprop: 'image',
          content: computed(() => dashboard.value && dashboard.value.openGraphOgImage?.length > 0
            ? imageUrlBuilder(dashboard.value.openGraphOgImage[0].url, { w: 800, auto: 'format' })
            : `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`),
          key: 'ogImage'
        },
        {
          name: 'og:locale',
          content: computed(() => dashboard.value?.openGraphOgLocale || 'en_IE'),
          key: 'ogLocale'
        }
      ]
    })

    const featuredNews = computed(() => {
      if (
        !dashboard.value?.featuredNewsWeb ||
        dashboard.value.featuredNewsWeb.visibleOn.find(
          news => news.codename === 'web'
        ) === undefined
      ) {
        return null
      }
      return dashboard.value.featuredNewsWeb
    })

    return {
      featuredNews,
      dashboard,
      error,
      latestNews,
      newsError
    }
  }
})
