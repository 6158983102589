
import { defineComponent, computed, PropType } from 'vue'
import { truncate } from '@/helpers'
import imageUrlBuilder from '@/kentico-api/imageUrlBuilder'
import { Destination } from '@/models/Destination'

export default defineComponent({
  props: {
    destination: {
      type: Object as PropType<Destination>,
      required: true
    }
  },
  setup (props) {
    const maxWidth = 223
    const imageHeight = 168
    const summary = computed(() => truncate(props.destination.body, 70))
    const transformedImageUrl = computed(() =>
      imageUrlBuilder(props.destination.image.url, {
        w: maxWidth,
        h: imageHeight,
        fit: 'crop',
        auto: 'format'
      })
    )

    const cardStyles = computed(() => ({
      backgroundImage: `url(${transformedImageUrl.value})`,
      maxWidth: `${maxWidth}px`,
      paddingTop: `${imageHeight + 13}px`
    }))

    return {
      summary,
      cardStyles
    }
  }
})
